import React, {useEffect} from "react";
import {useState, useCallback} from "react";
import logo from "./assets/mathew-logo-red.png";
import {useParams} from "react-router-dom";
import {PayPalScriptProvider, PayPalButtons} from "@paypal/react-paypal-js";
import "./Receipt.css";

import axios from "axios";

export default function (props) {
  let {id, ein, business} = useParams();
  const [step, setStep] = useState(5);
  const [loginError, setLoginError] = useState(false);
  const [dataError, setDataError] = useState(false);
  const [name, setName] = useState("");
  const [service, setService] = useState("");
  const [amount, setAmount] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [success, setSuccess] = useState(false);
  const [paypalError, setPaypalError] = useState("");
  const [paymentType, setPaymentType] = useState("Ach");
  const [loading, setLoading] = useState(false);
  const [achError, setAchError] = useState(false);
  const [inputValues, setInputValues] = useState({
    email: "",
    digits: "",
  });
  const [accountNo, setAccountNo] = useState("");
  const [routingNo, setRoutingNo] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [fullName, setFullName] = useState("");
  const [lastFour, setLastFour] = useState("");
  const [lastFourError, setLastFourError] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [ip, setIP] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState();

  function onChangeValue(event) {
    setPaymentType(event.target.value);
  }
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleOnChange = useCallback((event) => {
    const {name, value} = event.target;
    setInputValues({...inputValues, [name]: value});
  });

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: service,
            amount: {
              currency_code: "USD",
              value: (amount * 1.035).toFixed(2),
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      finalSubmit(details);
    });
  };
  //capture likely error
  const onError = (data, actions) => {
    setStep(2);
    setPaypalError(true);
  };

  const login = () => {
    setLoginError(false);
    setDataError(false);
    setLoading(true);

    if (
      (inputValues.email && inputValues.digits) ||
      (inputValues.email && ein)
    ) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Amz-Security-Token": id,
        },
        body: JSON.stringify({
          id,
          email: inputValues.email,
          ...(business ? {ein} : {ssn: inputValues.digits}),
        }),
      };
      fetch(
        business
          ? "https://fwlwf93ye8.execute-api.us-east-1.amazonaws.com/dev/api/customer/businesslist"
          : "https://fwlwf93ye8.execute-api.us-east-1.amazonaws.com/dev/api/customer/list",
        requestOptions
      )
        .then((response) => {
          setLoading(false);
          if (response.ok) {
            return response.json();
          }
          throw new Error("API Error");
        })
        .then((data) => {
          setName(
            data.response.name
              .toLowerCase()
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ")
          );
          setService(data.response.service);
          setAmount(data.response.amount);
          setAccountNo(data.response.accountNo);
          setRoutingNo(data.response.routingNo);
          if (data.response.signatureInfo) {
            setStep(4);
          } else {
            setStep(2);
          }
          setLoginError(false);
        })
        .catch((error) => {
          setStep(1);
          setLoginError(true);
        });
    } else {
      setLoginError(true);
      setDataError(true);
      setLoading(false);
    }
  };

  const getOsName = () => {
    var OSName = "Unknown OS";

    if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";

    if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";

    if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";

    if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";
    return OSName;
  };
  const finalSubmit = (details) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Amz-Security-Token": id,
      },
      body: JSON.stringify({
        id,
        email: inputValues.email,
        ...(business ? {ein} : {ssn: inputValues.digits}),
        paymentType,
        accountNo,
        routingNo,
        signatureInfo: {
          fullName,
          ...(business ? {ein} : {ssn: inputValues.digits}),
          currentTimeStamp: new Date().toString(),
          ip,
          osName: getOsName(),
        },
        paymentDetails: details,
      }),
    };
    fetch(
      "https://fwlwf93ye8.execute-api.us-east-1.amazonaws.com/dev/api/customer/update",
      requestOptions
    )
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          return response.json();
        }
        throw new Error("API Error");
      })
      .then((data) => {
        setStep(4);
        setSubmitError(false);
      })
      .catch((error) => {
        setStep(2);
        setSubmitError(true);
      });
    setLoading(false);
  };
  const clickSubmit = () => {
    clearError();
    setLoading(true);
    if (validatePaymentForm() && agreeTerms && fullName && lastFour) {
      if (paymentType === "PayPal") {
        setStep(3);
      } else {
        finalSubmit();
      }
      setLoading(false);
    } else if (validatePaymentForm() && agreeTerms && fullName && business) {
      if (paymentType === "PayPal") {
        setStep(3);
      } else {
        finalSubmit();
      }
    } else {
      showError();
      setLoading(false);
    }
  };
  const showError = () => {
    if (!lastFour) {
      setLastFourError(true);
    }
  };
  const clearError = () => {
    setAchError(false);
  };
  const validatePaymentForm = () => {
    if (paymentType === "Ach") {
      if (isNaN(routingNo) || !routingNo) {
        if (
          inputValues.email.toLowerCase() === "bvgopal@hotmail.com" ||
          inputValues.email.toLowerCase() === "sabarishs@hotmail.com"
        ) {
          return true;
        } else {
          setAchError(true);
          return false;
        }
      }
      if (isNaN(accountNo) || !accountNo) {
        if (
          inputValues.email.toLowerCase() === "bvgopal@hotmail.com" ||
          inputValues.email.toLowerCase() === "sabarishs@hotmail.com"
        ) {
          return true;
        } else {
          setAchError(true);
          return false;
        }
      }
      return true;
    } else if (
      paymentType === "ZelleBankTRF" ||
      paymentType === "PayPal" ||
      paymentType === "Check"
    ) {
      return true;
    }
  };

  return (
    <div>
      {step === 1 && (
        <div className="Auth-form-container">
          <form className="Auth-form">
            <div className="Auth-form-content">
              <img src={logo} />
              <h3 className="Auth-form-title">Sign In</h3>

              <div className="form-group mt-3">
                <label>Customer Email Id</label>

                <input
                  name="email"
                  type="email"
                  className="form-control mt-1"
                  placeholder="Enter email"
                  onChange={handleOnChange}
                />
              </div>
              {!business && (
                <div className="form-group mt-3">
                  <label>Last 4 digits of SSN</label>
                  <br />
                  <input
                    name="digits"
                    type="password"
                    className="form-control mt-1"
                    placeholder="Last 4 digits of SSN"
                    maxLength="4"
                    onChange={handleOnChange}
                  />
                </div>
              )}
              <div className="d-grid gap-2 mt-3">
                {loginError && <span className="red">Invalid Entry</span>}

                {loading ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <div className="btn btn-primary" onClick={() => login()}>
                    Submit
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      )}
      {step === 5 && (
        <div class="page-content container">
          <div class="page-header text-blue-d2">
            <h1 class="page-title text-secondary-d1">
              Invoice
              <small class="page-info">
                <i class="fa fa-angle-double-right text-80"></i>
                ID: #111-222
              </small>
            </h1>

            <div class="page-tools">
              <div class="action-buttons">
                <a
                  class="btn bg-white btn-light mx-1px text-95"
                  href="#"
                  data-title="Print"
                >
                  <i class="mr-1 fa fa-print text-primary-m1 text-120 w-2"></i>
                  Print
                </a>
                <a
                  class="btn bg-white btn-light mx-1px text-95"
                  href="#"
                  data-title="PDF"
                >
                  <i class="mr-1 fa fa-file-pdf-o text-danger-m1 text-120 w-2"></i>
                  Export
                </a>
              </div>
            </div>
          </div>

          <div class="container px-0">
            <div class="row mt-4">
              <div class="col-12 col-lg-12">
                <div class="row">
                  <div class="col-12">
                    <div class="text-center text-150">
                      <img src={logo} />
                    </div>
                  </div>
                </div>

                <hr class="row brc-default-l1 mx-n1 mb-4" />
                <div class="row">
                  <div class="col-sm-6">
                    <div>
                      <span class="text-sm text-grey-m2 align-middle">To:</span>
                      <span class="text-600 text-110 text-blue align-middle">
                        {name}
                      </span>
                    </div>
                    <div class="text-grey-m2">
                      <div class="my-1">Street, City</div>
                      <div class="my-1">State, Country</div>
                      <div class="my-1">
                        <i class="fa fa-phone fa-flip-horizontal text-secondary"></i>{" "}
                        <b class="text-600">111-111-111</b>
                      </div>
                    </div>
                  </div>

                  <div class="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                    <hr class="d-sm-none" />
                    <div class="text-grey-m2">
                      <div class="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                        Invoice
                      </div>

                      <div class="my-2">
                        <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                        <span class="text-600 text-90">ID:</span> #111-222
                      </div>

                      <div class="my-2">
                        <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                        <span class="text-600 text-90">Issue Date:</span> Oct
                        12, 2019
                      </div>

                      <div class="my-2">
                        <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                        <span class="text-600 text-90">Status:</span>{" "}
                        <span class="badge badge-warning badge-pill px-25">
                          Unpaid
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <div class="row text-600 text-white bgc-default-tp1 py-25">
                    <div class="d-none d-sm-block col-1">#</div>
                    <div class="col-9 col-sm-5">Service</div>
                    <div class="col-2">Amount</div>
                  </div>

                  <div class="text-95 text-secondary-d3">
                    <div class="row mb-2 mb-sm-0 py-25">
                      <div class="d-none d-sm-block col-1">1</div>
                      <div class="col-9 col-sm-5">{service}</div>
                      <div class="col-2 text-secondary-d2">$ {amount}</div>
                    </div>

                    {/* <div class="row mb-2 mb-sm-0 py-25 bgc-default-l4">
                      <div class="d-none d-sm-block col-1">2</div>
                      <div class="col-9 col-sm-5">Web hosting</div>
                      <div class="d-none d-sm-block col-2 text-95">$15</div>
                      <div class="col-2 text-secondary-d2">$15</div>
                    </div> */}
                  </div>

                  <div class="row mt-3">
                    {/* <div class="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                      Thank you for your payment
                    </div> */}

                    <div class="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                      {/* <div class="row my-2">
                        <div class="col-7 text-right">SubTotal</div>
                        <div class="col-5">
                          <span class="text-120 text-secondary-d1">$2,250</span>
                        </div>
                      </div> */}

                      {/* <div class="row my-2">
                        <div class="col-7 text-right">Tax (10%)</div>
                        <div class="col-5">
                          <span class="text-110 text-secondary-d1">$225</span>
                        </div>
                      </div> */}

                      <div class="row my-2 align-items-center bgc-primary-l3 p-2">
                        <div class="col-7 text-right">Total Amount</div>
                        <div class="col-5">
                          <span class="text-150 text-success-d3 opacity-2">
                            ${amount}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div style={{textAlign: "center"}}>
                    <span class="text-secondary-d1 text-105">
                      www.mathewscpainc.com | mathews.chacko@mathewscpainc.com |
                      Address: 1908 Yaupon Trl, Suite 102, Cedar Park, 78613 |
                      Ph: 512-980-1000, 512-710-1000
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
