import React from 'react';
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/mathew-logo-red.png';
import { useParams } from 'react-router-dom';

export default function(props) {
	return (
		<body>
			<div class="d-flex align-items-center justify-content-center vh-100">
				<div class="text-center">
					<img src={logo} />
					<h1 class="display-1 fw-bold">404</h1>
					<p class="fs-3">
						{' '}
						<span class="text-danger">Opps!</span> Page not found.
					</p>
					<p class="lead">The page you’re looking for doesn’t exist.</p>
				</div>
			</div>
		</body>
	);
}
