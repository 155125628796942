import React, {useEffect} from "react";
import {useState, useCallback} from "react";
import logo from "./assets/mathew-logo-red.png";
import {useParams} from "react-router-dom";
import {PayPalScriptProvider, PayPalButtons} from "@paypal/react-paypal-js";
import axios from "axios";

export default function (props) {
  let {id, ein, business} = useParams();
  const [step, setStep] = useState(1);
  const [loginError, setLoginError] = useState(false);
  const [dataError, setDataError] = useState(false);
  const [name, setName] = useState("");
  const [service, setService] = useState("");
  const [amount, setAmount] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [success, setSuccess] = useState(false);
  const [paypalError, setPaypalError] = useState("");
  const [paymentType, setPaymentType] = useState("Ach");
  const [loading, setLoading] = useState(false);
  const [achError, setAchError] = useState(false);
  const [inputValues, setInputValues] = useState({
    email: "",
    digits: "",
  });
  const [accountNo, setAccountNo] = useState("");
  const [routingNo, setRoutingNo] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreeTermsError, setAgreeTermsError] = useState(false);
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState(false);
  const [lastFour, setLastFour] = useState("");
  const [lastFourError, setLastFourError] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [ip, setIP] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState();

  function onChangeValue(event) {
    setPaymentType(event.target.value);
  }
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleAgreeTerms = useCallback((event) => {
    const {name, checked} = event.target;
    setAgreeTerms(checked);
  });
  const handleRoutingChange = useCallback((event) => {
    const {name, value} = event.target;
    setRoutingNo(value);
  });
  const handleAccountChange = useCallback((event) => {
    const {name, value} = event.target;
    setAccountNo(value);
  });
  const handleFullName = useCallback((event) => {
    const {name, value} = event.target;
    setFullName(value);
  });
  const handleLastFour = useCallback((event) => {
    const {name, value} = event.target;
    setLastFour(value);
  });
  const handleOnChange = useCallback((event) => {
    const {name, value} = event.target;
    setInputValues({...inputValues, [name]: value});
  });
  const calculate = (serviceType) => {
    return amount * 1.035;
  };

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: service,
            amount: {
              currency_code: "USD",
              value: (amount * 1.035).toFixed(2),
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      finalSubmit(details);
    });
  };
  //capture likely error
  const onError = (data, actions) => {
    setStep(2);
    setPaypalError(true);
  };

  const login = () => {
    setLoginError(false);
    setDataError(false);
    setLoading(true);

    if (
      (inputValues.email && inputValues.digits) ||
      (inputValues.email && ein)
    ) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Amz-Security-Token": id,
        },
        body: JSON.stringify({
          id,
          email: inputValues.email,
          ...(business ? {ein} : {ssn: inputValues.digits}),
        }),
      };
      fetch(
        business
          ? "https://fwlwf93ye8.execute-api.us-east-1.amazonaws.com/dev/api/customer/businesslist"
          : "https://fwlwf93ye8.execute-api.us-east-1.amazonaws.com/dev/api/customer/list",
        requestOptions
      )
        .then((response) => {
          setLoading(false);
          if (response.ok) {
            return response.json();
          }
          throw new Error("API Error");
        })
        .then((data) => {
          setName(
            data.response.name
              .toLowerCase()
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ")
          );
          setService(data.response.service);
          setAmount(data.response.amount);
          setAccountNo(data.response.accountNo);
          setRoutingNo(data.response.routingNo);
          if (data.response.signatureInfo) {
            setStep(4);
          } else {
            setStep(2);
          }
          setLoginError(false);
        })
        .catch((error) => {
          setStep(1);
          setLoginError(true);
        });
    } else {
      setLoginError(true);
      setDataError(true);
      setLoading(false);
    }
  };

  const getOsName = () => {
    var OSName = "Unknown OS";

    if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";

    if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";

    if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";

    if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";
    return OSName;
  };
  const finalSubmit = (details) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Amz-Security-Token": id,
      },
      body: JSON.stringify({
        id,
        email: inputValues.email,
        ...(business ? {ein} : {ssn: inputValues.digits}),
        paymentType,
        accountNo,
        routingNo,
        signatureInfo: {
          fullName,
          ...(business ? {ein} : {ssn: inputValues.digits}),
          currentTimeStamp: new Date().toString(),
          ip,
          osName: getOsName(),
        },
        paymentDetails: details,
      }),
    };
    fetch(
      "https://fwlwf93ye8.execute-api.us-east-1.amazonaws.com/dev/api/customer/update",
      requestOptions
    )
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          return response.json();
        }
        throw new Error("API Error");
      })
      .then((data) => {
        setStep(4);
        setSubmitError(false);
      })
      .catch((error) => {
        setStep(2);
        setSubmitError(true);
      });
    setLoading(false);
  };
  const clickSubmit = () => {
    clearError();
    setLoading(true);
    if (validatePaymentForm() && agreeTerms && fullName && lastFour) {
      if (paymentType === "PayPal") {
        setStep(3);
      } else {
        finalSubmit();
      }
      setLoading(false);
    } else if (validatePaymentForm() && agreeTerms && fullName && business) {
      if (paymentType === "PayPal") {
        setStep(3);
      } else {
        finalSubmit();
      }
    } else {
      showError();
      setLoading(false);
    }
  };
  const showError = () => {
    if (!agreeTerms) {
      setAgreeTermsError(true);
    }
    if (!fullName) {
      setFullNameError(true);
    }
    if (!lastFour) {
      setLastFourError(true);
    }
  };
  const clearError = () => {
    setAgreeTermsError(false);
    setFullNameError(false);
    setAchError(false);
  };
  const validatePaymentForm = () => {
    if (paymentType === "Ach") {
      if (isNaN(routingNo) || !routingNo) {
        if (
          inputValues.email.toLowerCase() === "bvgopal@hotmail.com" ||
          inputValues.email.toLowerCase() === "sabarishs@hotmail.com"
        ) {
          return true;
        } else {
          setAchError(true);
          return false;
        }
      }
      if (isNaN(accountNo) || !accountNo) {
        if (
          inputValues.email.toLowerCase() === "bvgopal@hotmail.com" ||
          inputValues.email.toLowerCase() === "sabarishs@hotmail.com"
        ) {
          return true;
        } else {
          setAchError(true);
          return false;
        }
      }
      return true;
    } else if (
      paymentType === "ZelleBankTRF" ||
      paymentType === "PayPal" ||
      paymentType === "Check"
    ) {
      return true;
    }
  };

  return (
    <div>
      {step === 1 && (
        <div className="Auth-form-container">
          <form className="Auth-form">
            <div className="Auth-form-content">
              <img src={logo} />
              <h3 className="Auth-form-title">Sign In</h3>

              <div className="form-group mt-3">
                <label>Customer Email Id</label>

                <input
                  name="email"
                  type="email"
                  className="form-control mt-1"
                  placeholder="Enter email"
                  onChange={handleOnChange}
                />
              </div>
              {!business && (
                <div className="form-group mt-3">
                  <label>Last 4 digits of SSN</label>
                  <br />
                  <input
                    name="digits"
                    type="password"
                    className="form-control mt-1"
                    placeholder="Last 4 digits of SSN"
                    maxLength="4"
                    onChange={handleOnChange}
                  />
                </div>
              )}
              <div className="d-grid gap-2 mt-3">
                {loginError && <span className="red">Invalid Entry</span>}

                {loading ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <div className="btn btn-primary" onClick={() => login()}>
                    Submit
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      )}
      {step === 2 && (
        <div className="container justify-content-center">
          <div className="row">
            <div className="col">
              <br />
              <br />
              <img src={logo} />
              <br /> <br />
              <br />
              <p>
                Dear {name},<br />
                <br />
                Thank you for your business!
                <br /> <br />
                <div>
                  <b>Service Taken: {service} </b>
                </div>
                <div>
                  <b>Amount Due: $ {amount}</b>
                </div>
              </p>
              <b>Choose one of the following payment option:</b>
              <br />
              <br />
              <div onChange={onChangeValue}>
                <input
                  type="radio"
                  value="Ach"
                  name="paymentType"
                  checked={paymentType === "Ach"}
                />{" "}
                <b>Option 1: ACH (No Service Fee)</b>
                <br />
                <input
                  type="radio"
                  value="PayPal"
                  name="paymentType"
                  checked={paymentType === "PayPal"}
                />{" "}
                <b>Option 2: Paypal / Credit Card (3.5% Service Fee)</b>
                <br />
                <br />
              </div>
              {paymentType == "ZelleBankTRF" && (
                <p>
                  You can zelle the amount of <b>$ {amount} </b>to {` `}
                  <b>Mathews.chacko@mathewscpainc.com</b>. Once you finish the
                  transfer submit the form below. Our team will validate and
                  send a confirmation to you once filing is done.
                </p>
              )}
              {paymentType == "PayPal" && (
                <p>
                  Please go ahead and submit the form, you will be shown payment
                  option.
                </p>
              )}
              {paymentType == "Check" && (
                <p>
                  You can mail or drop the check to Mathews CPA Inc, 1908 Yaupon
                  Trail Unit 102 Cedar Park TX 78613. We will be filing your
                  return once we receive the check.
                </p>
              )}
              {paymentType == "Ach" && (
                <p>
                  Please provide the following details for ACH. Once you confirm
                  the details, our team will trigger an ACH payment from your
                  account. Once the payment is confirmed we will go ahead with
                  the payment.
                  <br />
                  {achError && (
                    <span className="red">
                      <br />
                      Invalid ACH Information
                    </span>
                  )}
                  <div className="form-group mt-3">
                    <label>Routing Number</label>

                    <input
                      name="rountingnumber"
                      type="text"
                      className="form-control mt-1"
                      placeholder="Routing Number"
                      onChange={handleRoutingChange}
                      value={routingNo}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Account Number</label>

                    <input
                      name="accountnumber"
                      type="text"
                      className="form-control mt-1"
                      placeholder="Enter Account Number"
                      onChange={handleAccountChange}
                      value={accountNo}
                    />
                  </div>
                </p>
              )}
              <br />
              <div>
                {agreeTermsError && (
                  <div>
                    <span className="red">
                      <br />
                      Please agree to terms by enabling check box
                      <br />
                    </span>
                  </div>
                )}
                <input
                  type="checkbox"
                  name="agree"
                  id="agree"
                  onChange={handleAgreeTerms}
                  style={{width: 25, height: 25}}
                />{" "}
                <b>
                  I {name}, hereby confirm that I have carefully reviewed the
                  draft return document and have authorized Mathews CPA Inc to
                  file the return on behalf of me. I also agree to make a
                  payment of $ {amount} for the services provided.
                </b>
              </div>
              <br />
              <div className="col-sm">
                <label>Your Official Name:</label>

                {fullNameError && (
                  <div>
                    <span className="red">
                      <br />
                      Please fill in your full Name
                    </span>
                  </div>
                )}
                <br />
                <input
                  name="digits"
                  type="text"
                  className="form-control mt-1"
                  placeholder="Type your Full Name"
                  onChange={handleFullName}
                />
              </div>
              {!business && (
                <div className="col-sm">
                  <label>Last 4 digits of SSN</label>
                  {lastFourError && (
                    <div>
                      <span className="red">
                        <br />
                        Please fill in your last four digits of ssn
                      </span>
                    </div>
                  )}
                  <br />
                  <input
                    name="digits"
                    type="password"
                    className="form-control mt-1"
                    placeholder="Last 4 digits of SSN"
                    maxLength="4"
                    onChange={handleLastFour}
                  />
                </div>
              )}
              <div className="d-grid gap-2 mt-3">
                {loginError && <span className="red">Invalid Entry</span>}
                {paypalError && (
                  <span className="red">Payment Error please retry</span>
                )}

                {loading ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <div
                    className="btn btn-primary"
                    onClick={() => clickSubmit()}
                  >
                    Submit
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="container content-center">
          <div className="row">
            <div className="col">
              <br />
              <br />
              <div className="d-flex align-items-center">
                <img src={logo} />
              </div>
              <br />
              <br />
              <p>
                <b>Please proceed with the payment</b>
              </p>
              <br />
              <PayPalScriptProvider
                options={{
                  "client-id":
                    "AVg95p-R-zOPNWV-s7p_TckPGsd83pCQzaL71nlrKBklOWZ3gz990RWw75PDNDJSCsu-kQJAAlNIU_fQ",
                }}
              >
                <PayPalButtons
                  style={{layout: "vertical"}}
                  createOrder={createOrder}
                  onApprove={onApprove}
                  onError={onError}
                />
              </PayPalScriptProvider>
            </div>
          </div>
        </div>
      )}
      {step === 4 && (
        <div className="container justify-content-center">
          <div className="row">
            <div className="col">
              <br />
              <br />
              <img src={logo} />
              <br />
              <br />
              <p>
                <b>Dear {name},</b>
                <br />
                <br />
                Thank you for your business!
                <br />
                <br />
                {paymentType == "ZelleBankTRF" && (
                  <span>
                    Once you zelle the amount of <b>$ {amount} </b>to {` `}
                    <b>Mathews.chacko@mathewscpainc.com</b>, our team will
                    validate and send a confirmation to you once filing is done.
                  </span>
                )}
                {paymentType == "PayPal" && (
                  <p>
                    We will be soon filing your return and update you once the
                    filing is done.
                  </p>
                )}
                {paymentType == "Check" && (
                  <p>
                    You can mail or drop the check to Mathews CPA Inc, 1908
                    Yaupon Trail Unit 102 Cedar Park TX 78613. We will be filing
                    your return once we receive the check.
                  </p>
                )}
                {paymentType == "Ach" && (
                  <p>
                    We will be filing your return and collecting the funds
                    through ACH.
                  </p>
                )}{" "}
                Please reach out to us if you have any questions. <br />
                <br />
                Best regards, <br />
                <b>Mathews CPA Inc</b> <br />
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
