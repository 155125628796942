import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Auth from "./Auth";
import Receipt from "./Receipt";
import Error from "./Error";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/review/:id" element={<Auth />} />
        <Route path="/review/:business/:id/:ein" element={<Auth />} />
        <Route path="/receipt/:id" element={<Receipt />} />
        <Route path="/reciept/:business/:id/:ein" element={<Receipt />} />

        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
